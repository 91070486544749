import { flattenStrapiBulkDataItems } from 'lib/flattenStrapiBulkDataItems';
import { getHrefForUnitLink } from 'components/Search/utils/listingsFiltersUtils';
import { AutocompleteResponse, SearchItem } from '../types';
import { getRecentSearches } from 'components/Search/RecentSearches';

export const parseAutocompleteresponse = (
  searchText: string,
  data?: AutocompleteResponse
): SearchItem[] => {
  if (!data) return [];
  const {
    counties,
    addresses,
    buildings,
    cities,
    unitUniques,
    neighborhoods,
    preconstruction,
    zipcodes,
  } = data;

  const recentSearches = getRecentSearches();

  const recentSearchesArray: SearchItem[] = recentSearches.map((it) => ({
    label: it.title,
    id: Date.now(),
    category: 'recent',
    slug: it.query,
  }));

  const countiesArray: SearchItem[] = flattenStrapiBulkDataItems(
    counties?.data
  ).map(({ id, name, slug }) => ({
    label: name,
    id,
    category: 'counties',
    slug,
  }));

  const citiesArray: SearchItem[] = flattenStrapiBulkDataItems(
    cities?.data
  ).map(({ id, name, slug }) => ({
    label: name,
    id,
    category: 'cities',
    slug,
  }));

  const nhArray: SearchItem[] = flattenStrapiBulkDataItems(
    neighborhoods?.data
  ).map(({ id, name, slug }) => ({
    label: name,
    id,
    category: 'neighborhoods',
    slug,
  }));

  const buildingsArray: SearchItem[] = flattenStrapiBulkDataItems(
    buildings?.data
  ).map(({ id, name, slug }) => ({
    label: name,
    id,
    category: 'buildings',
    slug,
  }));

  const unitUniquesArray: SearchItem[] = flattenStrapiBulkDataItems(
    unitUniques?.data
  ).map((listing) => ({
    label: listing.mlsListingId,
    id: listing.id,
    category: 'unitUniques',
    link: getHrefForUnitLink(listing),
  }));

  let zipCodes: Set<string> = new Set<string>();

  const addressesArray: SearchItem[] = flattenStrapiBulkDataItems(
    addresses?.data
  ).map(({ primaryAddress, mappingAddresses, id }) => {
    mappingAddresses?.forEach(({ zipCode }) => {
      zipCodes.add(zipCode);
    });
    return {
      label: primaryAddress,
      category: 'addresses',
      id,
    };
  });

  const zipcodesMap = new Map();

  flattenStrapiBulkDataItems(zipcodes?.data).map(({ mappingAddresses }) => {
    const addresses = mappingAddresses?.filter((addr) =>
      addr.zipCode.includes(searchText)
    );

    if (addresses)
      for (const address of addresses)
        if (address?.zipCode)
          zipcodesMap.set(address?.zipCode, {
            label: address?.zipCode,
            category: 'zipCodes',
          });
  });

  const preconstructionArray: SearchItem[] = flattenStrapiBulkDataItems(
    preconstruction?.data
  ).map(({ id, name, slug }) => ({
    label: name,
    id,
    category: 'preconstruction',
    slug,
  }));

  return countiesArray
    .concat(citiesArray)
    .concat(nhArray)
    .concat(buildingsArray)
    .concat(preconstructionArray)
    .concat(unitUniquesArray)
    .concat(addressesArray)
    .concat(Array.from(zipcodesMap.values()))
    .concat(recentSearchesArray);
};
