import {
  BuildingFiltersInput,
  ListingFiltersInput,
  NeighborhoodFiltersInput,
} from 'apolloClient/types/filters';
import { CitiesFilter, SearchBoxFilters } from '../types';

export const createSearchboxListingsFilter = (
  filters: SearchBoxFilters,
  searchText?: string
): ListingFiltersInput => {
  const listingFilter = {
    or: [
      {
        saleUnit: {
          building: {
            and: [
              {
                publishedAt: { notNull: true },
                neighborhood: {
                  publishedAt: { notNull: true },
                  city: {
                    publishedAt: { notNull: true },
                    county: {
                      publishedAt: { notNull: true },
                    },
                  },
                },
              },
            ],
          },
        },
      },
      {
        rentUnit: {
          building: {
            and: [
              {
                publishedAt: { notNull: true },
                neighborhood: {
                  publishedAt: { notNull: true },
                  city: {
                    publishedAt: { notNull: true },
                    county: {
                      publishedAt: { notNull: true },
                    },
                  },
                },
              },
            ],
          },
        },
      },
    ],
  };

  if (filters.zipCodes.length) {
    Object.assign(listingFilter, {
      address: {
        zipCode: { in: filters.zipCodes },
      },
    });
  }

  if (searchText) {
    Object.assign(listingFilter, {
      mlsListingId: { containsi: searchText },
      statusCode: { ne: 'C' },
    });
  }

  return listingFilter;
};

export const createBuildingsFilter = (
  searchText: string,
  comparcionField: 'name' | 'primaryAddress',
  isPreconstruction?: boolean
): BuildingFiltersInput => {
  return {
    publishedAt: { notNull: true },
    and: [
      { [comparcionField]: { containsi: searchText } },
      ...(!!isPreconstruction
        ? [
            {
              or: [{ isPreconstruction: { eq: true } }],
            },
          ]
        : [
            {
              or: [
                { isPreconstruction: { eq: false } },
                { isPreconstruction: { null: true } },
              ],
            },
          ]),
      {
        neighborhood: {
          publishedAt: { notNull: true },
          city: {
            publishedAt: { notNull: true },
            county: {
              publishedAt: { notNull: true },
            },
          },
        },
      },
    ],
  };
};

export const createZipcodesFilter = (
  searchText: string,
  filters: SearchBoxFilters
): BuildingFiltersInput => {
  return {
    publishedAt: { notNull: true },
    and: [
      {
        mappingAddresses: {
          zipCode: {
            containsi: searchText,
          },
        },
      },
      {
        ...(filters.zipCodes.length
          ? {
              mappingAddresses: {
                zipCode: {
                  notIn: filters.zipCodes,
                },
              },
            }
          : {}),
      },
      {
        neighborhood: {
          publishedAt: { notNull: true },
          city: {
            publishedAt: { notNull: true },
            county: {
              publishedAt: { notNull: true },
            },
          },
        },
      },
    ],
  };
};

export const createNhFilter = (
  searchText: string,
  filters: SearchBoxFilters
): NeighborhoodFiltersInput => {
  return {
    publishedAt: { notNull: true },
    name: { containsi: searchText },
    city: {
      publishedAt: { notNull: true },
      county: {
        publishedAt: { notNull: true },
      },
    },
  };
};
