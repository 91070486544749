import React, { useEffect, useState } from 'react';
import { Chip, Box, Menu, MenuProps, Link as MuiLink } from '@mui/material';
import { styled } from '@mui/system';
import {
  FilterRootFieldNameMap,
  QueriesKeys,
  RootField,
  SearchBoxFilters,
  SearchItem,
  UpdateValue,
} from '../types';
import { getFiltersTotal } from '../utils/getFiltersTotal';
import CategoryBox from './CategoryBox';
import LineBetweenSections from 'components/LineBetweenSections';

const renderRoot = (
  rootType: RootField,
  filters: SearchBoxFilters,
  onDelete: (field: QueriesKeys, value: UpdateValue) => void
) => {
  const entityName = FilterRootFieldNameMap[rootType];
  return filters[entityName]?.map(
    ({ name: rootName, id: rootId, slug: rootSlug }) => (
      <React.Fragment key={`${rootType}-${rootId}-${rootSlug}`}>
        <div className="flex flex-col">
          <StyledChip
            variant="outlined"
            label={rootName}
            onDelete={() => {
              onDelete(entityName, {
                name: rootName,
                id: rootId,
                slug: rootSlug,
              });
            }}
          />
        </div>
      </React.Fragment>
    )
  );
};

const FiltersTree: React.FC<{
  onDelete: (field: QueriesKeys, value: UpdateValue) => void;
  filters: SearchBoxFilters;
  recent?: SearchItem[];
}> = ({ onDelete, filters, recent }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        paddingTop: '17px',
        paddingBottom: '3px',
        paddingX: '19px',
      }}
    >
      {renderRoot('county', filters, onDelete)}
      {renderRoot('city', filters, onDelete)}
      {renderRoot('neighborhood', filters, onDelete)}
      {renderRoot('building', filters, onDelete)}
      {renderRoot('address', filters, onDelete)}
      {filters.zipCodes?.length ? (
        <CategoryBox sx={{ display: 'flex', flexWrap: 'wrap' }}>
          {filters.zipCodes.map((zipCode) => (
            <StyledChip
              key={zipCode}
              variant="outlined"
              label={zipCode}
              onDelete={() =>
                onDelete('zipCodes', {
                  name: zipCode,
                })
              }
            />
          ))}
        </CategoryBox>
      ) : null}
      <section className="w-full pt-[11px]">
        <div className="text-14-18-0.3 text-gray font-sohneBuch pl-[2px]">
          Recent
        </div>
        <LineBetweenSections
          hideLine={
            'py-[8px] md:py-[8px] lg:py-[8px] px-[3px] md:px-[3px] lg:px-[3px]'
          }
        />
        <div className="py-[3px] px-[3px]">
          {recent?.map((search) => {
            return (
              <MuiLink
                key={(search?.slug || search?.label) + Math.random()}
                href={`/search?${search.slug}`}
                className="flex !text-14-18-0.3 !leading-[27px] font-sohneBuch !text-black !no-underline"
                title={search.label}
              >
                {search.label}
              </MuiLink>
            );
          })}
        </div>
      </section>
    </Box>
  );
};

const StyledMenu = styled((props: MenuProps) => (
  <Menu elevation={0} {...props} />
))(() => ({
  '& .MuiPaper-root': {
    '@media (min-width:1024px)': {
      top: '142px !important',
      left: '50px !important',
    },
    '@media (max-width:1023px)': {
      top: '142px !important',
      left: '45px !important',
    },
    '@media (max-width:767px)': {
      top: '205px !important',
      left: '17px !important',
    },
    width: '347px',
  },
}));

const StyledChip = styled(Chip)(() => ({
  height: 30,
  borderRadius: '8px',
  borderColor: '#CEBF7F',
  marginRight: '4px',
  marginBottom: '7px',
  fontFamily: 'Sohne Buch',
  fontSize: '14px',
  lineHeight: '18px',
  '& .MuiChip-deleteIcon': {
    color: 'black',
    marginRight: '4px',
    paddingBottom: '1px',
    paddingTop: '1px',
  },
  '@media (max-width:768px)': {
    maxWidth: '120px!important',
  },
  '@media (min-width:769px)': {
    maxWidth: '200px!important',
  },
}));

const ChipsAdornment: React.FC<{
  onDelete: (field: QueriesKeys, value: UpdateValue) => void;
  filters?: SearchBoxFilters;
  recent?: SearchItem[];
}> = ({ filters, onDelete, recent }) => {
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLDivElement) | null
  >(null);

  const total = getFiltersTotal(filters);
  useEffect(() => {
    if (total < 2) {
      setAnchorEl(null);
    }
  }, [total]);

  if (!total || !filters) return null;

  const renderFiltersPopper = () =>
    total > 1 && (
      <>
        <StyledMenu
          id="basic-menu"
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <FiltersTree filters={filters} onDelete={onDelete} recent={recent} />
        </StyledMenu>
      </>
    );

  const oneFilter: any = Object.entries(filters)
    .filter((res) => res[1].length > 0)
    .slice(-1)[0];
  return (
    <>
      {total > 1 && (
        <StyledChip
          className="mr-2"
          variant="outlined"
          label={`${total - 1} more`}
          onClick={(e: React.MouseEvent<HTMLDivElement>) =>
            setAnchorEl(anchorEl ? null : e.currentTarget)
          }
        />
      )}
      {oneFilter && (
        <StyledChip
          variant="outlined"
          label={`${
            oneFilter
              ? oneFilter[0] == 'zipCodes'
                ? oneFilter[1][0]
                : oneFilter[1][0].name
              : ''
          } `}
          onDelete={
            oneFilter[0] == 'zipCodes'
              ? () =>
                  onDelete(oneFilter[0], {
                    name: oneFilter[1][0],
                  })
              : () =>
                  onDelete(oneFilter[0], {
                    name: oneFilter[1][0].name,
                    id: oneFilter[1][0].id,
                    slug: oneFilter[1][0].slug,
                  })
          }
        />
      )}
      {renderFiltersPopper()}
    </>
  );
};

export default ChipsAdornment;
