import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { debounce } from '@mui/material';
import { LOAD_SEARCH_INPUT_ITEMS } from 'apolloClient/queries/searchPage';
import { parseAutocompleteresponse } from './parseAutocompleteresponse';
import {
  AutocompleteResponse,
  initialSearchBoxFilters,
  PaginableFields,
  SearchBoxFilters,
  SearchItem,
} from '../types';
import {
  createBuildingsFilter,
  createNhFilter,
  createSearchboxListingsFilter,
  createZipcodesFilter,
} from './createFilters';
import {
  BuildingFiltersInput,
  CityFiltersInput,
  CountyFiltersInput,
  ListingFiltersInput,
  NeighborhoodFiltersInput,
} from 'apolloClient/types/filters';

const defaultPaginationVariables = {
  loadAddresses: false,
  loadBuildings: false,
  loadListings: false,
  loadNeighborhoods: false,
  loadPreconstruction: false,
  loadOnlyPaginated: true,
};

enum LoadFlagMap {
  neighborhoods = 'loadNeighborhoods',
  buildings = 'loadBuildings',
  preconstruction = 'loadPreconstruction',
  unitUniques = 'loadListings',
  addresses = 'loadAddresses',
  zipcodes = 'loadZipcodes',
}

enum EntityPageMap {
  neighborhoods = 'neighborhoodsPage',
  buildings = 'buildingsPage',
  preconstruction = 'preconstructionPage',
  unitUniques = 'listingsPage',
  addresses = 'addressesPage',
  zipcodes = 'zipcodesPage',
}

const isLastPage = (entityName: PaginableFields, data?: AutocompleteResponse) =>
  data?.[entityName] &&
  (data?.[entityName].meta?.pagination.page || 0) <
    (data?.[entityName].meta?.pagination.pageCount || 0);

export const useSearchAutocomplete = () => {
  const [loadSearhItems, { data, loading, fetchMore }] = useLazyQuery<
    AutocompleteResponse,
    {
      citiesFilter: CityFiltersInput;
      countiesFilter: CountyFiltersInput;
      loadOnlyPaginated: boolean;
      loadBuildings: boolean;
      buildingsPage?: number;
      loadPreconstruction: boolean;
      preconstructionPage?: number;
      loadAddresses: boolean;
      addressesPage?: number;
      loadListings: boolean;
      listingsPage?: number;
      loadNeighborhoods: boolean;
      neighborhoodsPage?: number;
      nhFilters: NeighborhoodFiltersInput;
      buildingFilters: BuildingFiltersInput;
      preconstructionFilters: BuildingFiltersInput;
      addressesFiltersFilters: BuildingFiltersInput;
      listingFilters: ListingFiltersInput;
      loadZipcodes: boolean;
      zipcodesPage?: number;
      zipcodesFiltersFilters: BuildingFiltersInput;
    }
  >(LOAD_SEARCH_INPUT_ITEMS, { fetchPolicy: 'network-only' });

  const [items, setItems] = useState<SearchItem[]>(
    parseAutocompleteresponse('', {} as AutocompleteResponse)
  );

  const debouncedLoad = useCallback(
    debounce(
      (
        searchText: string,
        filters: SearchBoxFilters = initialSearchBoxFilters
      ) =>
        loadSearhItems({
          variables: {
            citiesFilter: {
              name: { containsi: searchText },
              ...(filters.cities && filters.cities.length
                ? { id: { notIn: filters.cities.map(({ id }) => id) } }
                : {}),
              publishedAt: { notNull: true },
            },
            countiesFilter: {
              name: { containsi: searchText },
              ...(filters.counties && filters.counties.length
                ? { id: { notIn: filters.counties.map(({ id }) => id) } }
                : {}),
              publishedAt: { notNull: true },
            },
            loadAddresses: true,
            loadBuildings: true,
            loadListings: true,
            loadZipcodes: true,
            loadOnlyPaginated: false,
            loadPreconstruction: true,
            addressesPage: 1,
            buildingsPage: 1,
            listingsPage: 1,
            zipcodesPage: 1,
            preconstructionPage: 1,
            loadNeighborhoods: true,
            neighborhoodsPage: 1,
            nhFilters: createNhFilter(searchText, filters),
            buildingFilters: createBuildingsFilter(searchText, 'name', false),
            preconstructionFilters: createBuildingsFilter(
              searchText,
              'name',
              true
            ),
            addressesFiltersFilters: createBuildingsFilter(
              searchText,
              'primaryAddress'
            ),
            zipcodesFiltersFilters: createZipcodesFilter(searchText, filters),
            listingFilters: createSearchboxListingsFilter(filters, searchText),
          },
        }).then((response) =>
          setItems(parseAutocompleteresponse(searchText, response.data))
        ),
      400
    ),
    [loadSearhItems]
  );

  const loadMore = (entity: PaginableFields) => {
    const flagName = LoadFlagMap[entity];
    const entityPageName = EntityPageMap[entity];
    const currentPage = data?.[entity]?.meta?.pagination.page || 0;

    const variables = {
      ...defaultPaginationVariables,
      [entityPageName]: currentPage + 1,
      [flagName]: true,
    };

    fetchMore({
      variables,
      updateQuery(previousQueryResult, options) {
        if (previousQueryResult[entity] && options.fetchMoreResult[entity]) {
          const nextResult = {
            ...previousQueryResult,
            [entity]: {
              ...previousQueryResult[entity],
              data: (previousQueryResult[entity].data as Array<unknown>).concat(
                options.fetchMoreResult[entity].data
              ),
              meta: options.fetchMoreResult[entity].meta,
            },
          };
          setItems(parseAutocompleteresponse('', nextResult));
          return nextResult;
        }
        return previousQueryResult;
      },
    });
  };

  const needLoadMore: { [key in PaginableFields]?: boolean } = {
    addresses: isLastPage('addresses', data),
    buildings: isLastPage('buildings', data),
    unitUniques: isLastPage('unitUniques', data),
    neighborhoods: isLastPage('neighborhoods', data),
    preconstruction: isLastPage('preconstruction', data),
    zipcodes: isLastPage('zipcodes', data),
  };

  return {
    fetchItems: debouncedLoad,
    items,
    loadMore,
    loading,
    needLoadMore,
  };
};
