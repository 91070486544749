import { Box, useTheme } from '@mui/material';
import React from 'react';

interface CategoryBoxProps {
  children: React.ReactNode;
  sx?: object;
}

const CategoryBox: React.FC<CategoryBoxProps> = ({ children, sx }) => {
  const {
    palette: { grey },
  } = useTheme();
  return <Box sx={sx}>{children}</Box>;
};

export default CategoryBox;
