import { SearchBoxFilters } from '../types';

export const getFiltersTotal = (filters?: SearchBoxFilters) => {
  if (!filters) return 0;
  return (
    (filters.zipCodes?.length || 0) +
    (filters.cities?.length || 0) +
    (filters.counties?.length || 0) +
    (filters.neighborhoods?.length || 0) +
    (filters.buildings?.length || 0) +
    (filters.addresses?.length || 0)
  );
};
