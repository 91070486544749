import React, { useMemo, useState } from 'react';
import { AutocompleteProps } from '@mui/material';
import { cloneDeep } from 'lodash';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import SearchField from './SearchField';
import {
  QueriesKeys,
  SearchBoxFilters,
  SearchItem,
  UpdateValue,
} from './types';
import { PropertyType } from 'components/UnitsFilters/types';
import {
  addFilterHandlersMap,
  deleteFilterHandlersMap,
} from './utils/handleFiltersChange';

type SearchInputProps = {
  onChangeFilters: (values: SearchBoxFilters) => void;
  filters?: SearchBoxFilters;
  noScroll?: boolean;
  iconPosition?: string;
  homePageClass?: string;
  activeTab?: PropertyType;
} & Partial<AutocompleteProps<SearchItem, false, false, false>>;

const defaultFilters = {
  counties: [],
  cities: [],
  zipCodes: [],
  neighborhoods: [],
  buildings: [],
  addresses: [],
};

const SearchInput: React.FC<SearchInputProps> = ({
  onChangeFilters,
  filters,
  noScroll,
  iconPosition,
  homePageClass,
  placeholder,
  activeTab,
  ...autocompleteProps
}) => {
  const [isConfirmDialogOpened, setIsConfirmDialogOpened] = useState(false);
  const [closeConfirmDialogHandler, setCloseConfirmDialogHandler] = useState<
    ((val: boolean) => void) | null
  >(null);

  const openConfirmDialog = (closeHandler: ((val: boolean) => void) | null) => {
    setCloseConfirmDialogHandler(() => closeHandler);
    setIsConfirmDialogOpened(true);
  };

  const closeConfirmDialog = () => setIsConfirmDialogOpened(false);

  const handleConfirmDialogClose = (isConfirmed: boolean) => {
    closeConfirmDialogHandler && closeConfirmDialogHandler(isConfirmed);
    closeConfirmDialog();
  };

  const addFilter = async (
    category: Exclude<QueriesKeys, 'unitUniques'>,
    value: UpdateValue
  ) => {
    const newFilters = await addFilterHandlersMap[category](
      value,
      cloneDeep(filters || defaultFilters),
      openConfirmDialog
    );
    onChangeFilters(newFilters);
  };

  const deleteFilter = async (
    category: Exclude<QueriesKeys, 'unitUniques'>,
    value: UpdateValue
  ) => {
    if (!filters) return;
    const newFilters = await deleteFilterHandlersMap[category](
      value,
      cloneDeep(filters)
    );
    onChangeFilters(newFilters);
  };

  return (
    <>
      <Dialog onClose={handleConfirmDialogClose} open={isConfirmDialogOpened}>
        <DialogTitle>Filters conflict!</DialogTitle>
        <DialogContent>
          The new choice conflicts with previous filters. If you continue - all
          previous filters will be cleared.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleConfirmDialogClose(false)}>
            Disagree
          </Button>
          <Button onClick={() => handleConfirmDialogClose(true)}>Agree</Button>
        </DialogActions>
      </Dialog>
      <SearchField
        {...autocompleteProps}
        filters={filters}
        onAdd={addFilter}
        onDelete={deleteFilter}
        noScroll={noScroll}
        iconPosition={iconPosition}
        homePageClass={homePageClass}
        placeholder={placeholder}
        activeTab={activeTab}
      />
    </>
  );
};

export default SearchInput;
