import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  TextField,
  Autocomplete,
  CircularProgress,
  Typography,
  Link as MuiLink,
  List,
  ListSubheader,
  Button,
  Divider,
  AutocompleteProps,
} from '@mui/material';
import { Box } from '@mui/system';

import {
  AutocompleteCategories,
  PaginableFields,
  QueriesKeys,
  SearchBoxFilters,
  SearchItem,
  UpdateValue,
} from './types';
import { PropertyType } from 'components/UnitsFilters/types';
import ChipsAdornment from './ChipsAdornment';
import { useSearchAutocomplete } from './utils/useSearchAutocomplete';
import AutocompleteListBox from './AutocompleteListBox';
import Image from 'next/legacy/image';
import searchIcon from 'public/images/search.svg';
import { TYPE_SALE } from '../utils/listingsFiltersUtils';

const categoriesLabels: { [index in AutocompleteCategories]: string } = {
  counties: 'Counties',
  cities: 'Cities',
  neighborhoods: 'Neighborhoods',
  buildings: 'Buildings',
  zipCodes: 'Zip Codes',
  unitUniques: 'MLS#',
  addresses: 'Addresses',
  recent: 'Recent',
  preconstruction: 'Pre-construction',
};

const SearchField: React.FC<
  {
    filters?: SearchBoxFilters;
    onAdd: (category: QueriesKeys, value: UpdateValue) => void;
    onDelete: (category: QueriesKeys, value: UpdateValue) => void;
    noScroll?: boolean;
    iconPosition?: string;
    homePageClass?: string;
    activeTab?: PropertyType;
  } & Partial<AutocompleteProps<SearchItem, false, false, false>>
> = ({
  filters,
  onAdd,
  onDelete,
  noScroll,
  iconPosition,
  homePageClass,
  placeholder = 'Address, Neighborhood or Zip',
  activeTab,
  ...autocompleteProps
}) => {
  const { items, loadMore, loading, fetchItems, needLoadMore } =
    useSearchAutocomplete();

  const [value, setValue] = useState<SearchItem | null>(null);
  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    if (filters?.addresses.length) {
      setValue({
        ...filters.addresses[0],
        label: filters.addresses[0].name,
        category: 'addresses',
      });
    }
  }, [filters]);

  const router = useRouter();

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      goToSearch();
    }
  };

  const goToSearch = () => {
    if (router.pathname !== '/search') {
      if (activeTab === TYPE_SALE) {
        router.push(
          '/search?preConstruction=true&newConstruction=true&foreclosures=true&shortSales=true&existingCondos=true&forSale=true&sortOption=Recommended'
        );
      } else router.push('/search?forRent=true&sortOption=Recommended');
    }
  };

  const sortOptionsByCategory = (
    options: SearchItem[],
    inputLength: number
  ): SearchItem[] => {
    const categoryOrder: { [key in AutocompleteCategories]?: number } = {
      zipCodes: 5,
      counties: 1,
      cities: 2,
      neighborhoods: 3,
      buildings: 4,
      preconstruction: 6,
      addresses: 7,
      recent: 8,
    };

    const filteredOptions = options.filter((option) =>
      inputLength === 0
        ? option.category === 'recent'
        : option.category === 'recent'
        ? false
        : option.category === 'zipCodes'
        ? inputLength > 2
        : true
    );

    const sortedOptions = filteredOptions.sort(
      (a: SearchItem, b: SearchItem) => {
        const aOrder = categoryOrder[a.category] || 9;
        const bOrder = categoryOrder[b.category] || 9;
        if (aOrder !== bOrder) {
          return aOrder - bOrder;
        }
        return a.category.localeCompare(b.category);
      }
    );

    return sortedOptions;
  };

  const recentOptions = items.filter((item) => item.category === 'recent');

  const hasNonEmptyArray = (obj: { [key: string]: any[] }): boolean => {
    if (obj) {
      return Object.values(obj).some(
        (value) => Array.isArray(value) && value.length > 0
      );
    } else return false;
  };

  return (
    <Autocomplete
      disablePortal={true}
      className="border border-beige-dark md:border-0"
      {...autocompleteProps}
      clearOnBlur={false}
      ListboxProps={{
        style: {
          overscrollBehavior: 'contain',
          backgroundColor: '#fff',
        },
      }}
      ListboxComponent={AutocompleteListBox}
      id="tags-filled"
      options={sortOptionsByCategory(items, inputValue.length) || []}
      filterOptions={(options) => options}
      isOptionEqualToValue={() => true}
      groupBy={(option) => option.category}
      loading={loading}
      fullWidth
      value={value}
      inputValue={inputValue}
      onChange={(_e, value) => {
        if (!value || value.category === 'unitUniques') return;
        setValue(null);
        if (!homePageClass) setTimeout(() => setInputValue(''), 0);
        if (value.category === 'recent') {
          window.location = `/search?${
            value.slug || ''
          }` as unknown as Location;
          return;
        }
        onAdd(
          value.category === 'preconstruction' ? 'buildings' : value.category,
          {
            name: value?.label,
            slug: value?.slug,
            id: value?.id,
          }
        );
      }}
      onInputChange={(_event, newInputValue, reason) => {
        if (!newInputValue) {
          setInputValue('');
          setValue(null);
          return;
        } else {
          setInputValue(newInputValue);
        }
        setInputValue(newInputValue);
        fetchItems(newInputValue, filters);
        if (!noScroll) {
          window.scrollTo(0, 0);
        }
      }}
      renderGroup={({ group, children }) => {
        return (
          <li key={group}>
            <List>
              <ListSubheader
                sx={{
                  fontWeight: 700,
                  top: -8,
                  display: 'flex',
                  alignItems: 'center',
                  paddingBottom: '10px',
                }}
              >
                <Box
                  height={2}
                  width={16}
                  mr={1}
                  sx={{ background: 'black' }}
                />
                <div className="font-sohneKraftig !text-16-21-0.3 text-black">
                  {categoriesLabels[group as AutocompleteCategories]}
                </div>
              </ListSubheader>
              {children}
              {needLoadMore[group as PaginableFields] && (
                <Button
                  fullWidth
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    loadMore(group as PaginableFields);
                  }}
                >
                  Load more...
                </Button>
              )}
              <Box px={5}>
                <Divider />
              </Box>
            </List>
          </li>
        );
      }}
      renderOption={({ onChange, onClick, ...props }, item) => {
        return item.category === 'unitUniques' ? (
          <MuiLink
            sx={{ width: '100%' }}
            href={item.link as string}
            key={item.label + Math.random()}
            component={Link}
          >
            <Typography
              {...props}
              itemType="span"
              sx={{ paddingLeft: 40 }}
              variant="body2"
            >
              <Box component="span" pl={3} />
              {item.label}
            </Typography>
          </MuiLink>
        ) : (
          <Typography
            {...props}
            key={item.label + Math.random()}
            onClick={onClick}
            sx={{ paddingLeft: 40 }}
            variant="body2"
          >
            <Box component="span" pl={3} />
            {item.label}
          </Typography>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          className={`text-16-18-0.3 relative font-sohneBuch placeholder-black input-search ${homePageClass} ${
            filters && hasNonEmptyArray(filters) ? 'text-field-with-chips' : ''
          }`}
          placeholder={placeholder}
          onKeyUp={handleKeyPress}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <ChipsAdornment
                onDelete={onDelete}
                filters={filters}
                recent={recentOptions}
              />
            ),
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
                {!loading && (
                  <div
                    className={`cursor-pointer absolute right-[9px] top-[12px] h-[18px] w-[18px] bg-white ${iconPosition}`}
                  >
                    <Image src={searchIcon} alt="search" onClick={goToSearch} />
                  </div>
                )}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default SearchField;
