interface RecentSearch {
  title: string;
  query: string;
}

export const getRecentSearches = () => {
  try {
    return JSON.parse(
      localStorage.getItem('recent-searches') || '[]'
    ) as Array<RecentSearch>;
  } catch (error) {
    console.error('Error parsing JSON in getRecentSearches', error);

    return [] as Array<RecentSearch>;
  }
};

export const storeRecentSearch = (title: string, query: string = '') => {
  const searches = getRecentSearches();
  let result: RecentSearch[] = [];

  const filtered = searches.filter((search) => {
    return search.title !== title && search.query !== query;
  });

  if (filtered) {
    result = filtered.slice(0, 9);
  }

  result.unshift({
    title,
    query,
  });

  localStorage.setItem('recent-searches', JSON.stringify(result));
};
